import { useState } from "react";
import Swal from "sweetalert2";

function App() {
  const [show, setShow] = useState(false);
  const handleSubmit = async (form) => {
    form.preventDefault();
    const formData = new FormData(form.target);

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var body = JSON.stringify(object);
    let res = await fetch(
      " https://alfa-romeo-crm.trendhosto.com/api/v1/leads",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
        body,
      }
    );
    if (res.status !== 200 && res.status !== 201) {
      Swal.fire(``, `Please fill out all required inputs`, "error");
      return;
    }
    Swal.fire(
      `Thanks for contacting us`,
      `We will get back to you shortly`,
      "success"
    );
    form?.target?.reset();
  };
  return (
    <div className="App">
      <div className="container">
        <h1>ALFA ROMEO</h1>
        <form onSubmit={handleSubmit}>
          <label>
            <p>Select A Car Model</p>
            <select type="text" name="car_model" required>
              <option></option>
              <option value="0">GIULIA</option>
              <option value="1">STELVIO</option>
              <option value="2">TONALE</option>
            </select>
          </label>
          <label>
            <p>Full Name:</p>
            <input type="text" name="full_name" required />
          </label>
          <label>
            <p>Mobile/Phone no.:</p>
            <input type="text" name="phone" required />
          </label>
          <label>
            <p>Email:</p>
            <input type="email" name="email" required />
          </label>
          <label>
            <p>Address:</p>
            <input type="text" name="address" required />
          </label>
          <label className="own_car_model">
            <p>Job Title:</p>
            <input type="text" name="job_title" />
          </label>
          <label>
            <p>Do you Own a Car:</p>
            <label>
              <input
                type="radio"
                name="own_car"
                value="1"
                // required
                onClick={() => setShow(true)}
              />
              <span>Yes</span>
            </label>
            <label>
              <input
                type="radio"
                name="own_car"
                value="0"
                // required
                onClick={() => setShow(false)}
              />
              <span>No</span>
            </label>
          </label>
          {show && (
            <label className="own_car_model">
              <p>If Yes what is your Car Model:</p>
              <input type="text" name="own_car_model" />
            </label>
          )}
          <small>
            Fields with <span>*</span> is mandatory
          </small>
          <button>Get A Quote</button>
        </form>
      </div>
    </div>
  );
}

export default App;
